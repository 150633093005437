<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="5" :sm="15">
            <a-form-item label="名称">
              <a-input placeholder="请输入" v-model="queryParam.name" allowClear />
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="getData">查询</a-button>
<!--              <a-button style="margin-left: 8px" @click="() => (queryParam = {})">重置</a-button>-->
              <a-button style="margin-left: 8px" @click="reset">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-operator">
      <a-button v-if="addEnable" type="primary" icon="plus" @click="handleAdd">新建</a-button>
      <a-dropdown v-if="removeEnable && selectedRowKeys.length > 0">
        <a-button type="danger" icon="delete" @click="delByIds(selectedRowKeys)">删除</a-button>
      </a-dropdown>
    </div>
    <!-- 数据表格 -->
    <a-table
      :loading="tableLoading"
      :row-selection="rowSelection"
      :rowKey="(record) => record.id"
      :columns="columns"
      :data-source="tableData"
      bordered
      :pagination="page"
      :rowClassName="rowClassName"
      @change="changeTable"
    >
      <span slot="name" slot-scope="text">
        <a-tooltip>
          <template slot="title"> {{ text }} </template> {{ text }}
        </a-tooltip>
      </span>
      <span slot="index" slot-scope="text, row, index">{{ index + 1 }}</span>
      <span slot="logo" slot-scope="item">
        <ZoomMedia style="height: 20px" :value="item"></ZoomMedia>
      </span>

      <span slot="action" slot-scope="text, record">
        <a v-if="editEnabel" @click="handleEdit(record.id)">编辑</a>
        <a-divider v-if="removeEnable && record.deploymentId == null" type="vertical" />
        <a v-if="removeEnable && record.deploymentId == null" @click="delByIds([record.id])">删除</a>
        <a-divider type="vertical" />
        <a v-if="removeEnable" @click="deploy(record.id)">发布</a>
      </span>
    </a-table>
    <a-modal
      :visible="modelVisible"
      title="Activiti工作流在线流程设计编辑"
      :footer="null"
      :width="1500"
      centered
      :maskClosable="false"
      @cancel="modelCancel"
    >
      <div style="position: relative; height: 100%">
        <iframe id="iframe" :src="modelerUrl" frameborder="0" width="100%" height="720px" scrolling="auto"></iframe>
        <Spin fix size="large" v-if="modelerLoading"></Spin>
      </div>
    </a-modal>
    <a-modal v-model="addModel" title="请输入流程名称" @ok="handleName">
      <a-input placeholder="请输入流程名称" v-model="modelName" allowClear />
    </a-modal>
  </a-card>
</template>

<script>
import { STable } from '@/components'
import { getModelList, delModel, deploy, createModel } from '@/api/activiti'
import { checkPermission } from '@/utils/permissions'
const modelerBaseUrl = process.env.VUE_APP_MODEL_BASE_URL
export default {
  name: 'ModelList',
  components: {
    STable,
  },
  data() {
    return {
      addModel: false,
      modelName: '',
      tableData: [],
      queryParam: {}, // 搜索条件
      scroll: { y: 600 },
      tableLoading: false, //表格loading
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      selectedRowKeys: [],
      selectedRows: [],

      visible: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      form: this.$form.createForm(this),
      mdl: {},
      permissions: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 表头
      columns: [
        {
          title: '编号',
          dataIndex: 'id',
        },
        {
          title: '名称',
          dataIndex: 'name',
        },
        {
          title: '版本',
          dataIndex: 'version',
        },
        {
          title: '发布编号',
          dataIndex: 'deploymentId',
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
        },
        {
          title: '最后时间',
          dataIndex: 'lastUpdateTime',
        },
        {
          title: '操作',
          width: '150px',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
        },
      ],
      // 加载数据方法 必须为 Promise 对象
      // loadData: parameter => {
      //   return getModelList(Object.assign(parameter, this.queryParam)).then(res => {
      //     console.log(res)
      //     return res.rows
      //   })
      // },
      modelVisible: false,
      modelerUrl: null,
      modelerLoading: false,
      // addEnable: checkPermission('activiti:models:add'),
      // editEnabel: checkPermission('activiti:models:edit'),
      // removeEnable: checkPermission('activiti:models:remove')
      addEnable: true,
      editEnabel: true,
      removeEnable: true,
    }
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange,
        // getCheckboxProps: record => ({
        //   props: {
        //     disabled: record.deploymentId, // Column configuration not to be checked
        //     name: record.name
        //   }
        // })
      }
    },
  },
  filters: {},
  created() {
    this.getData()
  },
  methods: {
    handleName() {
      if (this.modelName == '') {
        this.$message.error('请输入模型名称')
        return
      }
      createModel({ name: this.modelName }).then((res) => {
        if (res.code === 200) {
          this.modelName = ''
          this.handleOk()
          this.$message.success(`流程创建成功，请配置流程`)
          this.addModel = false
          this.modelVisible = true
          this.modelerUrl =
            modelerBaseUrl + '/api/workflow/modeler.html?modelId=' + res.body + '&time=' + new Date().getTime()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 隔行换色
    rowClassName(record, index) {
      let className = 'light-row'
      if (index % 2 === 1) className = 'dark-row'
      return className
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },

    /**
     * 更改分页
     */
    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },

    handleAdd() {
      this.addModel = true
      // this.modelVisible = true
      // this.modelerUrl = modelerBaseUrl + '/api/workflow/models/newModel?time=' + new Date().getTime()
    },
    handleEdit(id) {
      this.modelVisible = true
      this.modelerUrl = modelerBaseUrl + '/api/workflow/modeler.html?modelId=' + id + '&time=' + new Date().getTime()
    },
    handleOk() {
      this.getData()
    },
    dataModal(dictType) {
      this.$refs.datamodal.show(dictType)
    },
    modelCancel() {
      this.modelVisible = false
      this.handleOk()
    },
    deploy(id) {
      const _that = this
      _that.$confirm({
        title: '确认发布',
        content: '确认要发布该模型吗?',
        onOk() {
          deploy(id).then((res) => {
            if (res.code === 200) {
              _that.$message.success(`发布成功`)
              _that.handleOk()
            } else {
              _that.$message.error(res.message)
            }
          })
        },
        onCancel() {},
      })
    },
    delByIds(ids) {
      const _that = this
      _that.$confirm({
        title: '确认删除',
        content: '确认要删除选择的模型?',
        onOk() {
          delModel({ ids: ids.join(',') }).then((res) => {
            if (res.code === 200) {
              _that.$message.success(`删除成功`)
              _that.handleOk()
            } else {
              _that.$message.error(res.message)
            }
          })
        },
        onCancel() {},
      })
    },

    reset(){
      this.queryParam = {}
      this.getData()
    },

    getData() {
      this.selectedRows = []
      this.selectedRowKeys = []
      this.tableLoading = true
      return getModelList({
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        ...this.queryParam,
      })
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
            this.tableData = records
          }
        })
        .finally(() => (this.tableLoading = false))
    },
  },
  watch: {
    /*
      'selectedRows': function (selectedRows) {
        this.needTotalList = this.needTotalList.map(item => {
          return {
            ...item,
            total: selectedRows.reduce( (sum, val) => {
              return sum + val[item.dataIndex]
            }, 0)
          }
        })
      }
      */
  },
}
</script>

<style lang="less" scoped>
::v-deep .ant-table-tbody > .light-row {
  background: #fff !important;
}
::v-deep .ant-table-tbody > .dark-row {
  background: #fafafa !important;
}
</style>
